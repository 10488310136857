define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", ["exports", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/site"], function (_exports, _object, _computed, _ajax, _ajaxError, _site) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newActor = _exports.default = _exports.actorModels = _exports.actorClientPath = _exports.actorAdminPath = void 0;
  const newActor = _exports.newActor = {
    id: "new",
    default_visibility: "public",
    post_object_type: "Note",
    publication_type: "first_post"
  };
  const actorModels = _exports.actorModels = ["category"];
  const actorAdminPath = _exports.actorAdminPath = "/admin/plugins/ap/actor";
  const actorClientPath = _exports.actorClientPath = "/ap/local/actor";
  const ActivityPubActor = _object.default.extend({
    isNew: (0, _computed.equal)("id", newActor.id),
    disable() {
      if (this.isNew) {
        return;
      }
      return (0, _ajax.ajax)(`${actorAdminPath}/${this.id}/disable`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    enable() {
      if (this.isNew) {
        return;
      }
      return (0, _ajax.ajax)(`${actorAdminPath}/${this.id}/enable`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    save() {
      let data = {
        actor: {
          enabled: this.enabled,
          model_id: this.model_id,
          model_type: this.model_type,
          model_name: this.model_name,
          username: this.username,
          name: this.name,
          default_visibility: this.default_visibility,
          publication_type: this.publication_type,
          post_object_type: this.post_object_type
        }
      };
      let type = "POST";
      let path = actorAdminPath;
      if (this.id !== "new") {
        path = `${path}/${this.id}`;
        type = "PUT";
      }
      return (0, _ajax.ajax)(path, {
        type,
        data
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  ActivityPubActor.reopenClass({
    find(actorId) {
      return (0, _ajax.ajax)({
        url: `${actorClientPath}/${actorId}`,
        type: "GET"
      }).then(response => response.actor || false).catch(_ajaxError.popupAjaxError);
    },
    findByHandle(actorId, handle) {
      return (0, _ajax.ajax)({
        url: `${actorClientPath}/${actorId}/find-by-handle`,
        type: "GET",
        data: {
          handle
        }
      }).then(response => response.actor || false).catch(_ajaxError.popupAjaxError);
    },
    findByModel(model, modelType) {
      const siteActors = _site.default.currentProp("activity_pub_actors");
      if (!siteActors) {
        return;
      }
      const typeActors = siteActors[modelType];
      if (!typeActors) {
        return;
      }
      return typeActors.find(a => {
        if (modelType === "tag") {
          if (typeof model === "string") {
            return a.model_name === model;
          } else {
            return [model.id, model.name].includes(a.model_name);
          }
        } else if (typeof model === "object" && model !== null) {
          return a.model_id === model.id;
        }
      });
    },
    follow(actorId, targetActorId) {
      return (0, _ajax.ajax)({
        url: `${actorClientPath}/${actorId}/follow`,
        type: "POST",
        data: {
          target_actor_id: targetActorId
        }
      }).then(response => !!response?.success).catch(_ajaxError.popupAjaxError);
    },
    unfollow(actorId, targetActorId) {
      return (0, _ajax.ajax)({
        url: `${actorClientPath}/${actorId}/follow`,
        type: "DELETE",
        data: {
          target_actor_id: targetActorId
        }
      }).then(response => !!response?.success).catch(_ajaxError.popupAjaxError);
    },
    reject(actorId, targetActorId) {
      return (0, _ajax.ajax)({
        url: `${actorClientPath}/${actorId}/reject`,
        type: "POST",
        data: {
          target_actor_id: targetActorId
        }
      }).then(response => !!response?.success).catch(_ajaxError.popupAjaxError);
    },
    list(actorId, params, listType) {
      const queryParams = new URLSearchParams();
      if (params.order) {
        queryParams.set("order", params.order);
      }
      if (params.asc) {
        queryParams.set("asc", params.asc);
      }
      const path = `${actorClientPath}/${actorId}/${listType}`;
      let url = `${path}.json`;
      if (queryParams.size) {
        url += `?${queryParams.toString()}`;
      }
      return (0, _ajax.ajax)(url).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubActor;
});