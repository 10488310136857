define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _object, _service, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    model(params) {
      return _activityPubActor.default.find(params.actor_id);
    }
    setupController(controller, model) {
      const actor = model;
      const props = {
        actor,
        category: null,
        tag: null,
        tags: []
      };
      if (actor.model_type === "category") {
        props.category = this.site.categories.find(c => c.id === actor.model_id);
      }
      if (actor.model_type === "tag") {
        const tag = this.store.createRecord("tag", {
          id: actor.model_name
        });
        props.tag = tag;
        props.tags = [tag.id];
        props.canCreateTopicOnTag = !actor.model.staff || this.currentUser?.staff;
      }
      controller.setProperties(props);
    }
    follow(actor, followActor) {
      return _activityPubActor.default.follow(actor.id, followActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).actors.unshiftObject(followActor);
        return result;
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "follow", [_object.action]))();
    unfollow(actor, followedActor) {
      return _activityPubActor.default.unfollow(actor.id, followedActor.id).then(result => {
        this.controllerFor(this.router.currentRouteName).actors.removeObject(followedActor);
        return result;
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "unfollow", [_object.action]))();
    reject(actor, follower) {
      return _activityPubActor.default.reject(actor.id, follower.id).then(result => {
        this.controllerFor(this.router.currentRouteName).actors.removeObject(follower);
        return result;
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "reject", [_object.action]))();
  }
  _exports.default = ActivityPubActorRoute;
});