define("discourse/plugins/discourse-activity-pub/discourse/templates/connectors/extra-nav-item/activity-pub-navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ActivityPubNavItem @category={{this.category}} @tag={{this.tag}} />
  */
  {
    "id": "n/S0syNE",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@tag\"],[[30,0,[\"category\"]],[30,0,[\"tag\"]]]],null]],[],false,[\"activity-pub-nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/connectors/extra-nav-item/activity-pub-navigation.hbs",
    "isStrictMode": false
  });
});